import "./App.css";
import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import Footer from "./components/Footer";
import Login from "./components/Login/Login";
import AppContext from "./components/Theme/AppContext";
import TokenProvider from "./components/TokenProvider/TokenProvider";
import { DEFAULT_THEME, getLocale, getTheme, setCurrentLocale, setCurrentTheme } from './components/Theme/ThemeUtils'
import { useRef } from "react";
import Home from "./components/Home/Home"
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import TripPage from "./components/TrackAndTrace/TripsPage";
import BackhaulPage from "./components/TrackAndTrace/BackhaulPage";
import CapacityManagement from "./components/CapacityManagement/CapacityManagement";
import RateCall from "./components/RateCall/RateCall";
import ReportComp from "./components/RateCall/Reports";
import RateCallDefaults from "./components/RateCall/RateCallDefaults";
import jwtDecode from "jwt-decode";
import { showToast } from "./components/Theme/Utils";
import { getJWTToken, getMetabaseToken, getMetabaseUserInfo, getUserInfo, isValidMetabaseRefreshToken, isValidMetabaseToken, isValidRefreshToken, isValidToken, setJWTToken, setMetabaseToken, setMetabaseUserInfo, setUserInfo } from "./components/Auth/ProtectedRoute";
import { useTranslation } from "react-i18next";
import NoPermissionPage from "./components/Auth/NoPermissionPage";
import agent from "./api/agent";
import Loading from "./components/Loading/Loading";
import Sidebar from "./components/Sidebar/Sidebar";
import DashBoard from "./components/DashBoard/DashBoard";
import moment from 'moment';

function App() {
	const { token, setToken } = TokenProvider();
	const { t, i18n } = useTranslation();

	const lightThemeUrl = "./bootstrap5.css";
	const darkThemeUrl = "./bootstrap5-dark.css";
	const [theme, setTheme] = useState(getTheme());
	const [localeState, setLocaleState] = useState(getLocale());
	const [userInfo, setUserInfoState] = useState(getUserInfo());
	const [isLoadingAuth, setIsLoadingAuth] = useState(false);
	const [metabaseCurrentToken, setMetabaseCurrentToken] = useState(null);

	useEffect(() => {
		const interval = setInterval(() => {
			const jwtToken = getJWTToken();
			if (jwtToken) {
				const decodedToken = jwtDecode(jwtToken);;
				const expTime = decodedToken.exp * 1000;
				const currentTime = new Date().getTime();
				const timeRemaining = expTime - currentTime;

				// if (timeRemaining <= 600000 && isValidToken()) {
				// 	// showToast(t("Your session is about to expire. Please logout."));
				// }
				if (timeRemaining <= 600000) {
					handleRefreshToken();
				}
				// for testing only
				// const issuedAt = decodedToken.iat * 1000; // Convert to milliseconds
				// const age = currentTime - issuedAt;
				// if (age >= 30000) { // Check if the token is at least 30 seconds old
				//   showToast(t("The token is at least 30 seconds old."));
				// }

				if(decodedToken && decodedToken.absoluteExpiryUTC){
					const currentTimeUTC = moment.utc(); // Get current date time in UTC using Moment.js
					const absoluteExpiryUTC = moment.utc(decodedToken.absoluteExpiryUTC, "M/D/YYYY h:mm:ss A"); // Parse absoluteExpiryUTC using Moment.js
					const timeRemaining2 = absoluteExpiryUTC.diff(currentTimeUTC); // Calculate difference in milliseconds
					// console.log("timeRemaining2 ", timeRemaining2);
					if (timeRemaining2 <= 1800000) {  // 30 mins : 1800000,  8 hours: 28800000 
						showToast(t("Your token is about to expire in 30 minutes."));
					}
				}
			} else {
				clearInterval(interval);
			}
		}, 10000); //60000   /// 30000
		return () =>{
			clearInterval(interval);
		};
	}, []);

	// useEffect(()=>{
	// 	const metabaseTokenInterval = setInterval(() => {
	// 		const metabaseToken = getMetabaseToken();
	// 		if (metabaseToken) {
	// 		// console.log("metabaseToken ", metabaseToken, "isValidMetabaseToken", isValidMetabaseToken());
	// 		  if (!isValidMetabaseToken()) {
	// 			handleMetabaseTokenRefresh();
	// 		  }
	// 		} else {
	// 		  clearInterval(metabaseTokenInterval);
	// 		}
	// 	  }, 17500); //17500 // Check every 2 minutes and 55 seconds
	// 	return () =>{ 
	// 		clearInterval(metabaseTokenInterval);
	// 	};
	// },[])

	useEffect(() => {
		setCurrentTheme(getTheme());
		setTheme(getTheme());
	}, [token, setToken]);

	function toggleTheme() {
		setCurrentTheme(theme === "light" ? "dark" : "light");
		setTheme(theme === "light" ? "dark" : "light");
		//setToken({...token, theme: theme});
	}

	const handleRefreshToken = () => {
		if(!isValidToken() && isValidRefreshToken()){
			const userInfo = getUserInfo();
			agent.getRefreshToken({token: userInfo.refreshToken}).then(res=>{
				if(res.data && res.data.token){
					let userInfo = getUserInfo();
					setUserInfo({...userInfo, ...res.data});
					setJWTToken(res.data.token)
				}
				// window.location.reload();
			})
			.catch(err=> console.log("err",err))
		}
	}

	// const handleMetabaseTokenRefresh = () => {
	// 	console.log("isValidMetabaseRefreshToken ", isValidMetabaseRefreshToken(), ", Actual metabse token: ", getMetabaseToken());
	// 	if(isValidMetabaseRefreshToken()){
	// 		const metabaseUserInfo = getMetabaseUserInfo();
	// 		console.log("isValidMetabaseRefreshToken ", isValidMetabaseRefreshToken(), " metabaseUserInfo", metabaseUserInfo);
	// 		agent.getRefreshMetebaseToken({token: metabaseUserInfo.refreshToken}).then(res=>{
	// 			let userInfo = getMetabaseUserInfo();
	// 			console.log('userInfo', userInfo);
	// 			setMetabaseUserInfo({...userInfo, ...res.data});
	// 			setMetabaseToken(res.data.token)
	// 			setMetabaseCurrentToken(res.data.token);
	// 			// window.location.reload();
	// 		})
	// 		.catch(err=> console.log("err",err))
	// 	}
	// }

	const themeUrl = theme === "light" ? lightThemeUrl : darkThemeUrl;

	if (!isValidToken() || isLoadingAuth) {
		return <Login isLoadingAuth={isLoadingAuth} setIsLoadingAuth={setIsLoadingAuth} setToken={setToken} token={token} setMetabaseCurrentToken={setMetabaseCurrentToken} />;
	}
	else {
		return (
			<AppContext.Provider
				value={{ theme, toggleTheme, userInfo, setUserInfoState, setLocaleState, localeState, metabaseCurrentToken }}
			//style={{ backgroundColor: (theme === "dark" ? "#141a17" : "white"), height: "100%"}}
			// data-bs-theme="dark"
			>
				<link rel="stylesheet" href={themeUrl} />
				<div>
				<ErrorBoundary compName="Customer Portal Whole App">
					<div className={"App-header " + (theme === "dark" ? "isDarkTheme" : " ")} style={{ backgroundColor: theme === "dark" ? "#141a17" : "white" }}>
						<Sidebar />
						<Routes>
							<Route path='/' element={<Home />} />
							<Route path='/track-trips' element={<TripPage />} />
							<Route path='/track-backhaul' element={<BackhaulPage />} />
							<Route path='/capacitymanagement' element={<CapacityManagement />} />
							<Route path='/ratecall-details' element={<RateCall />} />
							<Route path='/ratecall-report' element={<ReportComp />} />
							<Route path='/ratecall-defaults' element={<RateCallDefaults />} />
							{/* <Route path='/dashboard' element={<DashBoard />} /> */}
							<Route path='/dashboard' element={<DashBoard />} />
							{/* <Route path='/dashboard-Ops' element={<DashBoard />} />
							<Route path='/dashboard-Finance' element={<DashBoard />} /> */}
							<Route path='/access-denied' element={<NoPermissionPage />} />
						</Routes>
					</div>
					</ErrorBoundary>
					<Footer />
				</div>
			</AppContext.Provider>
		);
	}

}

export default App;
