import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { pdfjs } from 'react-pdf';
import Loading from "../Loading/Loading";
import { SizeMe } from 'react-sizeme'
import { useTranslation } from 'react-i18next';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = (props) => {
  const { t } = useTranslation();

  const { loadingTripStopImage, setLoadingTripStopImage, pdfContent, rotationAngle, pdfKey } = props;
  const [numPages, setNumPages] = useState(null);
  const [pdfData, setPdfData] = useState(null);

  useEffect(() => {
    try {
      setLoadingTripStopImage(true);
      const decodedPdfContent = atob(pdfContent);
      const uint8Array = new Uint8Array(decodedPdfContent.length);
      for (let i = 0; i < decodedPdfContent.length; i++) {
        uint8Array[i] = decodedPdfContent.charCodeAt(i);
      }
      // console.log("Decoded PDF Data:", uint8Array); // Log the decoded data
      setPdfData(uint8Array);
      setLoadingTripStopImage(false);
    } catch (error) {
      console.error("Error decoding PDF content:", error);
      setLoadingTripStopImage(false);
    }
  }, [pdfContent]);



  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div>
      <SizeMe
        monitorHeight
        refreshRate={128}
        refreshMode={"debounce"}
        render={({ size }) => (
          <div>
            {(pdfData && !loadingTripStopImage) ? (
              <Document
                key={pdfKey}
                file={`data:application/pdf;base64,${pdfContent}`}
                loading={t("Loading PDF...")}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {numPages && numPages && Array.from({ length: numPages }, (_, pageIndex) => (
                  <Page
                    key={`page_${pageIndex + 1}`}
                    width={size.width}
                    pageNumber={pageIndex + 1}
                    renderTextLayer={false}
                    rotate={rotationAngle}
                  />
                ))}
              </Document>
            ) : (
              <div className='my-custom-loader-container'>
                {/* <Loading /> */}
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
};

export default PdfViewer;